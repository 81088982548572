<template>
    <Layout color="white">
        <div class="pagina-produtos-banner" :style="'background-image: url(' + card.imagem_grande + ');'">
            <div class="pagina-produtos-container01">
                <div class="pagina-produtos-container02">
                    <h1 class="pagina-produtos-text" v-if="card.dscevento && card.dscevento.length > 10">{{ card.titulo || '' }}</h1>
                    <span class="pagina-produtos-text01" v-if="card.dscevento && card.dscevento.length > 10">
                        {{ card.dscevento || '' }}
                    </span>
                </div>
                <div class="pagina-produtos-container03">
                    <CardProdutosData v-if="$config.dados.selecionarData" :idevento="card.idevento" />
                    <CardProdutos v-else :idevento="card.idevento" />
                </div>
            </div>
        </div>

        <div class="pagina-produtos-container04" v-if="card.complementos && card.complementos.length > 0">
            <div class="pagina-produtos-container06">
                <div class="pagina-produtos-container07" v-for="(item, index) in card.complementos" :key="index">
                    <Carrousell :imagens="pos.find(item => item.ordem == index)" v-if="pos && pos.find(item => item.ordem == index)"/>
                    <Beneficios :imagens="ben.find(item => item.ordem == index)" v-if="ben && ben.find(item => item.ordem == index)"/>
                    <article style="width: 100%">
                        <h1 class="pagina-produtos-text03" v-html="item.titulo"></h1>
                        <div class="pagina-produtos-text07" v-html="item.texto"></div>
                    </article>
                </div>
            </div>
        </div>

    </Layout>
</template>

<script>

import { ENDPOINTS } from '@/plugins/service/Endpoints.js'
export default {
    name: 'PaginaProdutos',
    components: {
        Layout: () => import('@/layout/Default.vue'),
        CardProdutos: () => import('@/components/card-produtos-detalhe.vue'),
        CardProdutosData: () => import('@/components/card-produtos-detalhe-data.vue'),
        Carrousell: () => import('@/components/carrossel.vue'),
        Beneficios: () => import('@/components/conteudo-beneficios.vue')
    },

    data(){
        return {
            card: [],
            pos: [],
            ben: [],
        }
    },

    methods: {

        getCarrossel(){

            this.$config.getConteudo('carrossel_imagens', true)
            .then(response => {
                if(response && response.length > 0){
                    this.pos = response
                    this.pos.forEach(item => {
                        this.$axios.get(ENDPOINTS.GETCONTEUDO + item.idconteudo)
                        .then(res => {
                            let arry = this.$util.removeHTML(res.html).split(',')
                            item.tags = arry
                            item.model = 0
                            setTimeout(() => {
                                item.model++
                            }, 2000)
                        })
                    })
                }
            })
            
        },

        getBeneficios(){

            this.$config.getConteudo('beneficios', true)
            .then(response => {
                if(response && response.length > 0){
                    this.ben = response
                    this.ben.forEach(item => {
                        this.$axios.get(ENDPOINTS.GETCONTEUDO + item.idconteudo)
                        .then(res => {
                            let arry = this.$util.removeHTML(res.html)
                            arry       = JSON.stringify(arry)
                            arry       = JSON.parse(arry)
                            item.tags  = JSON.parse(arry)
                            item.model = 0
                            setTimeout(() => {
                                item.model++
                            }, 2000)
                        })
                    })
                }
            })
            
        },

        getEventos(){
            this.$superingresso.getEventos()
            .then(response => {
                if(response){
                    let eventos = response
                    eventos.forEach(item => {
                        let link = this.$superingresso.tratarLink(item.titulo)
                        if(link == this.$route.params.id){
                            this.card = item
                            this.getApresentacao(item.idapresentacao)
                        }
                    });
                }
            })
        },

        getApresentacao(idapresentacao){
            this.$superingresso.getApresentacao(idapresentacao) 
            .then(response => {
              if(response){

                response.imagem_grande = this.card?.imagem_grande?this.card.imagem_grande : require('@/assets/images/euryops.jpg')
                response.dscevento     = this.card.dscevento
                response.titulo        = this.card.titulo

                this.card              = response

                this.$carrinho.getApresentacao({ idapresentacao: idapresentacao})
                this.getCarrossel()
                this.getBeneficios()
              }
            })
        },
    },

    mounted() {
        this.getEventos()
    },
}
</script>

<style scoped>
.pagina-produtos-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.pagina-produtos-banner {
  width: 100%;
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-position: center;
}
.pagina-produtos-container01 {
  flex: 0 0 auto;
  width: 991px;
  display: flex;
  padding: 15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.pagina-produtos-container02 {
    flex: 0 0 auto;
    width: 40%;
    display: flex;
    padding: 15px;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.pagina-produtos-text {
    display: contents;
    color: var(--dl-color-gray-white);
    text-transform: uppercase;
    font-size: 22px;
    align-self: flex-start;
}
.pagina-produtos-text01 {
    color: var(--dl-color-gray-white);
}
.pagina-produtos-container03 {
    flex: 0 0 auto;
    width: 60%;
    display: flex;
    padding: 15px;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}
.pagina-produtos-container04 {
  flex: 0 0 auto;
  width: 991px;
  display: flex;
  padding: 15px;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
}
.pagina-produtos-container05 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.pagina-produtos-text02 {
  color: var(--dl-color-gray-500);
  font-size: 12px;
}
.pagina-produtos-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.pagina-produtos-container07 {
  flex: 0 0 auto;
  width: 80%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.pagina-produtos-text03 {
  width: 100%;
  margin-bottom: 15px;
}
.pagina-produtos-text07 {
  width: 100%;  
  color: var(--dl-color-gray-200);
}
.pagina-produtos-container08 {
  flex: 0 0 auto;
  width: 80%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: flex-start;
}
.pagina-produtos-image {
  width: 606px;
  height: 345px;
  object-fit: cover;
  margin-right: 20px;
}
.pagina-produtos-image1 {
  width: 340px;
  height: 346px;
  object-fit: cover;
  margin-right: 20px;
}
.pagina-produtos-image2 {
  width: 606px;
  height: 345px;
  object-fit: cover;
  margin-right: 20px;
}
.pagina-produtos-container09 {
  flex: 0 0 auto;
  width: 80%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.pagina-produtos-text21 {
  color: var(--dl-color-principal);
  width: 100%;
  margin-bottom: 15px;
}
.pagina-produtos-text22 {
  color: var(--dl-color-gray-700);
}
.pagina-produtos-container10 {
  flex: 0 0 auto;
  width: 80%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.pagina-produtos-text30 {
  color: var(--dl-color-principal);
  width: 100%;
  margin-bottom: 15px;
}
.pagina-produtos-text31 {
  color: var(--dl-color-gray-700);
}
.pagina-produtos-container11 {
  top: auto;
  flex: 0 0 auto;
  left: auto;
  right: 16px;
  width: 100%;
  bottom: 543px;
  display: flex;
  z-index: 999;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-principal);
}
.pagina-produtos-container12 {
  top: auto;
  flex: 0 0 auto;
  left: auto;
  right: 16px;
  width: 991px;
  bottom: 543px;
  display: flex;
  z-index: 999;
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.pagina-produtos-container13 {
  width: 50%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.pagina-produtos-text40 {
  color: var(--dl-color-gray-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-right: 5px;
  text-transform: uppercase;
}
.pagina-produtos-container14 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  padding: 0px;
  flex-wrap: wrap;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.pagina-produtos-text41 {
  color: var(--dl-color-gray-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-right: 5px;
}
.pagina-produtos-text42 {
  color: var(--dl-color-gray-white);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}
.pagina-produtos-text43 {
  color: var(--dl-color-gray-white);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.pagina-produtos-container15 {
  width: 50%;
  height: 100%;
  display: flex;
  padding: 10px;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.pagina-produtos-button {
  color: var(--dl-color-gray-black);
  height: 35px;
  font-size: 12px;
  align-self: flex-end;
  margin-top: 0px;
  text-align: center;
  padding-top: 8px;
  border-width: 0px;
  padding-bottom: 8px;
  text-transform: uppercase;
  background-color: var(--dl-color-gray-white);
}
@media(max-width: 991px) {
  .pagina-produtos-container01 {
    width: 100%;
  }
  .pagina-produtos-container02 {
    width: 100%;
  }
  .pagina-produtos-container03 {
    width: 100%;
    padding: 0px;
  }
  .pagina-produtos-container04 {
    width: 100%;
  }
  .pagina-produtos-container11 {
    width: 100%;
  }
  .pagina-produtos-container12 {
    width: 100%;
  }
  .pagina-produtos-container15 {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .pagina-produtos-container05 {
    width: 100%;
  }
  .pagina-produtos-container07 {
    width: 100%;
    flex-wrap: wrap;
  }
  .pagina-produtos-container08 {
    width: 100%;
    flex-wrap: wrap;
  }
  .pagina-produtos-container09 {
    width: 100%;
    flex-wrap: wrap;
  }
  .pagina-produtos-container10 {
    width: 100%;
    flex-wrap: wrap;
  }
}
@media(max-width: 479px) {
  .pagina-produtos-container05 {
    width: 100%;
  }
  .pagina-produtos-container07 {
    width: 100%;
    padding: 0px;
  }
  .pagina-produtos-container08 {
    width: 100%;
    padding: 0px;
  }
  .pagina-produtos-container09 {
    width: 100%;
    padding: 0px;
  }
  .pagina-produtos-container10 {
    width: 100%;
    padding: 0px;
  }
  .pagina-produtos-container11 {
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    background-color: var(--dl-color-gray-white);
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .pagina-produtos-container12 {
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    background-color: var(--dl-color-gray-white);
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .pagina-produtos-text40 {
    color: #d2232aff;
  }
  .pagina-produtos-text41 {
    color: #d2232aff;
  }
  .pagina-produtos-text42 {
    color: #d2232aff;
  }
  .pagina-produtos-text43 {
    color: #d2232aff;
  }
  .pagina-produtos-container15 {
    align-self: center;
  }
  .pagina-produtos-button {
    background-color: #d2232aff;
  }
}
</style>
